import React from "react";
import "../../styles/styles.scss";
import Header from "../header/header";
import { GetCompanyInfo } from "../../queries/company-info";
import Footer from "../footer/footer";

const Layout = ({ children }) => {
  const data = GetCompanyInfo();
  return (
    <>
      <Header data={data} />
      <main className="content">{children}</main>
      <Footer data={data}/>
    </>
  );
};

export default Layout;
