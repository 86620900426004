import CookieConsent from "./src/components/cookie-consent/cookie-consent";
import React from "react";
import store from "./store";
import { Provider } from "react-redux";
import Layout from "./src/components/layout/layout";

export const wrapPageElement = ({ element }) => (
  <Provider store={store}>
    <Layout>
      {element}
      <CookieConsent />
    </Layout>
  </Provider>
);
