import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { image } from "./image.module.scss";

const Image = ({ gatsbyImage, alt, loading, className }) => {
  const [opacity, setOpacity] = useState(0);
  const imageData =
    gatsbyImage?.localFile?.childImageSharp?.gatsbyImageData?.images;
  const webpSource = imageData?.sources[0];
  const fallback = imageData?.fallback;

  useEffect(() => {
    setOpacity(1);
  }, []);

  return (
    <picture className={`${className} ${image}`}>
      <source
        type={webpSource?.type}
        srcSet={webpSource?.srcSet}
        sizes={webpSource?.sizes}
      ></source>
      <img
        decoding="async"
        loading={loading ?? "lazy"}
        style={{ opacity }}
        srcSet={fallback?.srcSet}
        sizes={fallback?.sizes}
        alt={alt ?? gatsbyImage?.alternativeText ?? "Image"}
        src={fallback?.src || gatsbyImage?.url}
      ></img>
    </picture>
  );
};

export default Image;
