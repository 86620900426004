// extracted by mini-css-extract-plugin
export var cName = "footer-module--cName--32d9b";
export var contact = "footer-module--contact--2ada8";
export var contactDiv = "footer-module--contactDiv--9c9bb";
export var dBlock = "footer-module--d-block--7cddf";
export var dFlex = "footer-module--d-flex--43309";
export var dInline = "footer-module--d-inline--fdaba";
export var dLgBlock = "footer-module--d-lg-block--271dc";
export var dLgFlex = "footer-module--d-lg-flex--333ba";
export var dLgInline = "footer-module--d-lg-inline--6bb86";
export var dLgNone = "footer-module--d-lg-none--c3edb";
export var dMdBlock = "footer-module--d-md-block--18441";
export var dMdFlex = "footer-module--d-md-flex--56b22";
export var dMdNone = "footer-module--d-md-none--f0d4f";
export var dNone = "footer-module--d-none--83a31";
export var footer = "footer-module--footer--954ee";
export var footerLink = "footer-module--footerLink--c2271";
export var info = "footer-module--info--c8d02";
export var legal = "footer-module--legal--9da5f";
export var locationData = "footer-module--locationData--20bcc";
export var logo = "footer-module--logo--e67c9";
export var maps = "footer-module--maps--3a664";
export var underlined = "footer-module--underlined--27f6f";