import React, { useEffect, useState } from "react";
import {
  menuButton,
  menu,
  sticky,
  hidden,
  quickLinks,
  header,
  headerMain,
  headerLeftContent,
  headerCenterContent,
  logo,
} from "./header.module.scss";
import { Link } from "gatsby";
import Navigation from "../navigation/navigation";
import logoImage from "../../assets/images/logov2.png";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isStickyOpen, setIsStickyOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const close = () => {
    setIsOpen(false);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const headerHeight = getComputedStyle(document.documentElement)
      .getPropertyValue("--header-height")
      ?.replace("px", "");
    window.addEventListener("scroll", () => {
      setScrolled(window.pageYOffset > headerHeight / 2);
      setIsStickyOpen(false);
      setIsOpen(false);
    });
  }, []);

  return (
    <header id="header" className={`header ${header}`}>
      <div className={headerMain}>
        <Link to="/" className={logo} onClick={close}>
          <img src={logoImage} alt="RA Welte Logo" />
        </Link>
        <div className={menu}>
          <div className={quickLinks}>
            <Link to="/kontakt">→ Kontaktformular</Link>
            <Link to="#anfahrt">→ Anfahrt</Link>
          </div>
          <button onClick={() => toggle()} className={menuButton}>
            Menü
          </button>
        </div>
      </div>
      <Navigation isOpen={isOpen} close={close} />
      <div className={`${headerMain} ${sticky} ${scrolled ? "" : hidden}`}>
        <div className={headerCenterContent}>
          <Link to="/" className={logo} onClick={close}>
            <img src={logoImage} alt="RA Welte Logo" />
          </Link>
          <button
            onClick={() => setIsStickyOpen(!isStickyOpen)}
            className={menuButton}
          >
            Menü
          </button>
          <Navigation
            isOpen={isStickyOpen}
            close={() => setIsStickyOpen(false)}
            isInline={true}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
