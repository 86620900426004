import { graphql, useStaticQuery } from "gatsby";

export const GetCompanyInfo = () => {
  const siteData = useStaticQuery(graphql`
    query getCompanyInfo {
      strapiWebsite {
        company_info {
          email
          name
          phone
          phone_alternative
          location {
            data {
              location
            }
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
          maps_url
        }
      }
    }
  `);
  return siteData.strapiWebsite;
};
