import { Link } from "gatsby";
import React from "react";
import { nav, open, quickContact, inline } from "./navigation.module.scss";

const Navigation = ({ isOpen, close, isInline }) => {
  const links = [
    { to: "/#kanzlei", text: "Kanzlei" },
    { to: "/#team", text: "Team" },
    { to: "/#leistungen", text: "Leistungen" },
    { to: "/kontakt/", text: "Kontakt" },
  ];

  const onNavClick = () => {
    close();
  };

  return (
    <nav
      className={`${nav} ${isOpen ? `${open}` : ""} ${isInline ? inline : ""}`}
      onClick={() => onNavClick()}
      onKeyDown={() => close()}
    >
      {links.map((link) => (
        <Link key={link.to} to={link.to}>
          {link.text}
        </Link>
      ))}
      <div className={quickContact}>
        <a href="mailto: kanzlei@rawelte.at">kanzlei@rawelte.at</a>
        <a href="tel: +43552290212">+43 5522 90 212</a>
      </div>
    </nav>
  );
};

export default Navigation;
