import { createSlice } from "@reduxjs/toolkit";
import { getCookie } from "./consent-utils";

const getConsent = () => {
  const consent = getCookie("ml_consent") === "true";
  return consent;
};

export const consentSlice = createSlice({
  name: "consent",
  initialState: { active: getConsent(), showBanner: !getConsent() },
  reducers: {
    setConsent: (state, action) => {
      state.active = action.payload;
      state.showBanner = false;
    },
    setShowBanner: (state, action) => {
      state.showBanner = action.payload;
    },
  },
});

export const { setConsent, setShowBanner } = consentSlice.actions;

export default consentSlice.reducer;
