// extracted by mini-css-extract-plugin
export var dBlock = "navigation-module--d-block--77885";
export var dFlex = "navigation-module--d-flex--45e48";
export var dInline = "navigation-module--d-inline--4e590";
export var dLgBlock = "navigation-module--d-lg-block--74f03";
export var dLgFlex = "navigation-module--d-lg-flex--5bb35";
export var dLgInline = "navigation-module--d-lg-inline--e1528";
export var dLgNone = "navigation-module--d-lg-none--078e0";
export var dMdBlock = "navigation-module--d-md-block--e44a0";
export var dMdFlex = "navigation-module--d-md-flex--397c6";
export var dMdNone = "navigation-module--d-md-none--b8926";
export var dNone = "navigation-module--d-none--d4199";
export var inline = "navigation-module--inline--efe6e";
export var nav = "navigation-module--nav--3fb10";
export var open = "navigation-module--open--78888";
export var quickContact = "navigation-module--quickContact--a0d3f";