import { Link } from "gatsby";
import React from "react";
import ReactMarkdown from "react-markdown";
import Image from "../../components/image/image";
import {
  footer,
  contact,
  contactDiv,
  locationData,
  maps,
  cName,
  underlined,
  info,
  legal,
  footerLink,
} from "./footer.module.scss";

const Footer = ({ data }) => {
  const location = data.company_info.location.data.location;

  let phoneSplit = data.company_info.phone.split(/[` `]/g);
  phoneSplit.splice(1, 0, `(0)`);
  const displayTelNr = phoneSplit.join(` `);

  phoneSplit = data.company_info.phone_alternative.split(/[` `]/g);
  phoneSplit.splice(1, 0, `(0)`);
  const displayTelNrAlt = phoneSplit.join(` `);

  return (
    <section id="anfahrt">
      <footer className={footer}>
        <div className={maps}>
          {data.company_info.image ? (
            <a href={data.company_info.maps_url}>
              <Image gatsbyImage={data.company_info.image} />{" "}
            </a>
          ) : null}
        </div>
        <div className={info}>
          <div className={contact}>
            {/* <Link to="/" className={logo}>
                <img src={logoImage} alt="RA Welte Logo" />
                <span>Welte Rechtsanwalt GmbH</span>
              </Link> */}
            <h4 className={underlined}>Standort:</h4>
            <ReactMarkdown className={locationData}>{location}</ReactMarkdown>
            <div className={contactDiv}>
              <a href={`tel: ${data.company_info.phone}`}>
                {displayTelNr}
                <br></br>
              </a>
              <a href={`tel: ${data.company_info.phone_alternative}`}>
                {displayTelNrAlt}
              </a>
            </div>
            <div className={contactDiv}>
              <a href={`mailto: ${data.company_info.email}`}>
                {data.company_info.email}
              </a>
            </div>
            <p className={cName}>{data.company_info.name}</p>
            <br />
          </div>
          <div className={legal}>
            <h4 className={underlined}>Rechtliches:</h4>
            <Link className={footerLink} to={`/impressum/`}>
              → Impressum
            </Link>
            <br />
            <Link className={footerLink} to={`/datenschutz/`}>
              → Datenschutz
            </Link>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default Footer;
