// extracted by mini-css-extract-plugin
export var bannerActions = "cookie-consent-module--bannerActions--1e119";
export var cookieBanner = "cookie-consent-module--cookieBanner--72a5a";
export var dBlock = "cookie-consent-module--d-block--bb1b1";
export var dFlex = "cookie-consent-module--d-flex--4c616";
export var dInline = "cookie-consent-module--d-inline--d7649";
export var dLgBlock = "cookie-consent-module--d-lg-block--b4916";
export var dLgFlex = "cookie-consent-module--d-lg-flex--cb304";
export var dLgInline = "cookie-consent-module--d-lg-inline--6ecbe";
export var dLgNone = "cookie-consent-module--d-lg-none--b429f";
export var dMdBlock = "cookie-consent-module--d-md-block--8d1c1";
export var dMdFlex = "cookie-consent-module--d-md-flex--d1df5";
export var dMdNone = "cookie-consent-module--d-md-none--ab83d";
export var dNone = "cookie-consent-module--d-none--21a65";